<template>
  <div class="topbar-item mr-4">
    <div class="btn btn-clean d-inline-flex align-items-center" id="kt_quick_user_toggle">
      <div class="d-flex flex-column text-right pr-3">
        <span class="text-dark-75 font-weight-bold font-size-sm">{{ currentUserPersonalInfo.name }}</span>
        <span class="text-muted font-weight-bolder font-size-sm">{{ currentUserPersonalInfo.job }}</span>
      </div>
      <span class="symbol symbol-40">
        <span class="symbol-label">
            <span class="svg-icon svg-icon-lg pl-2">
              <inline-svg src="media/svg/icons/General/User.svg" />
            </span>
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          Kullanıcı Profili
        </h3>
        <a
          href="javascript:;"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex flex-column">
          <div class="d-flex align-items-flex-start mt-5">
            <div class="symbol symbol-80 mr-5">
              <img class="symbol-label" src="media/users/default.jpg" alt="" />
            </div>
            <div class="d-flex flex-column">
              <a
                href="#"
                class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                {{ currentUserPersonalInfo.name }} {{ currentUserPersonalInfo.surname }}
              </a>
              <div class="text-muted mt-1">{{ currentUserPersonalInfo.job }}</div>
              <div class="navi mt-2">
                <a href="#" class="navi-item">
                  <span class="navi-link p-0 pb-2">
                    <span class="navi-icon mr-1">
                      <span class="svg-icon svg-icon-lg svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          src="media/svg/icons/Communication/Mail-notification.svg"
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <span class="navi-text text-muted text-hover-primary">
                      {{ currentUserPersonalInfo.email }}
                    </span>
                  </span>
                </a>
              </div>
              
            </div>
            
          </div>
          <button class="btn btn-light-primary font-weight-bold flex-fill mt-6" @click="onLogout">
            Çıkış Yap
            <span class="svg-icon svg-icon-lg pl-2">
              <inline-svg src="media/svg/icons/Navigation/Sign-out.svg" />
            </span>
          </button>
        </div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { mapGetters } from "vuex";

export default {
  name: "KTQuickUser",
  data() {
    return {
      user: this.$store.state.auth.user,
      list: []
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    }
  }
};
</script>
